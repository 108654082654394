import React from 'react'

// Obras Públicas 
import Pav1 from '../assets/img/Pav1.png'
import Pav2 from '../assets/img/Pav2.png'
import PerDir1 from '../assets/img/PerDir1.png'
import PerDir2 from '../assets/img/PerDir2.png'
import Tub1 from '../assets/img/Tub1.png'
import Tub2 from '../assets/img/Tub2.png'
import AP1 from '../assets/img/AP1.png'
import AP2 from '../assets/img/AP2.png'
import AP3 from '../assets/img/AP3.png'
import Dre1 from '../assets/img/Dre1.png'
import Dre2 from '../assets/img/Dre2.png'
import Sold1 from '../assets/img/Sold1.png'
import Sold2 from '../assets/img/Sold2.png'
import Sold3 from '../assets/img/Sold3.png'
import Estruc1 from '../assets/img/Estruc1.png'
import Estruc2 from '../assets/img/Estruc2.png'

// AnteProyectos
import APDE1 from '../assets/img/AP-DE1.png'
import APDE2 from '../assets/img/AP-DE2.png'
import APDE3 from '../assets/img/AP-DE3.png'
import APDE4 from '../assets/img/AP-DE4.png'
import APDE5 from '../assets/img/AP-DE5.png'
import APDE6 from '../assets/img/AP-DE6.png'
import APDE7 from '../assets/img/AP-DE7.png'
import APDE8 from '../assets/img/AP-DE8.png'
import APDE9 from '../assets/img/AP-DE9.png'
import APDE10 from '../assets/img/AP-DE10.png'
import APDE11 from '../assets/img/AP-DE11.png'
import APDE12 from '../assets/img/AP-DE12.png'

// Casa Habitación y Oficinas
import Coco from '../assets/img/Coco1.png'
import ChO1 from '../assets/img/ChO1.png'
import ChO2 from '../assets/img/ChO2.png'
import ChO3 from '../assets/img/ChO3.png'
import ChO4 from '../assets/img/ChO4.png'
import ChO5 from '../assets/img/ChO5.png'
import ChO6 from '../assets/img/ChO6.png'
import ChO7 from '../assets/img/ChO7.png'
import ChO8 from '../assets/img/ChO8.png'
import ChO9 from '../assets/img/ChO9.png'
import ChO10 from '../assets/img/ChO10.png'


import ImageGalleryComponent from '../components/ImageGalleryComponent'


const obraPub = [
  {
    url: Pav1,
    thumbnail: Pav1,
    title: "Pavimentacion",
  },
  {
    url: Pav2,
    thumbnail: Pav2,
    title: "Pavimentacion",
  },
  {
    url: PerDir1,
    thumbnail: PerDir1,
    title: "Perforacion Direccional",
  },
  {
    url: PerDir2,
    thumbnail: PerDir2,
    title: "Perforacion Direccional",
  },
  {
    url: Tub1,
    thumbnail: Tub1,
    title: "Tubería",
  },
  {
    url: Tub2,
    thumbnail: Tub2,
    title: "Postería",
  },
  {
    url: AP1,
    thumbnail: AP1,
    title: "Agua Potable",
  },
  {
    url: AP2,
    thumbnail: AP2,
    title: "Agua Potable",
  },
  {
    url: AP3,
    thumbnail: AP3,
    title: "Agua Potable",
  },
  {
    url: Dre1,
    thumbnail: Dre1,
    title: "Drenaje",
  },
  {
    url: Dre2,
    thumbnail: Dre2,
    title: "Drenaje",
  },
  {
    url: Sold1,
    thumbnail: Sold1,
    title: "Soldadura y Estructura",
  },
  {
    url: Sold2,
    thumbnail: Sold2,
    title: "Soldadura y Estructura",
  },
  {
    url: Sold3,
    thumbnail: Sold3,
    title: "Soldadura y Estructura",
  },
  {
    url: Estruc1,
    thumbnail: Estruc1,
    title: "Soldadura y Estructura",
  },
  {
    url: Estruc2,
    thumbnail: Estruc2,
    title: "Soldadura y Estructura",
  },

];

const anteProyectos = [
  {
    url: APDE1,
    thumbnail: APDE1,
    title: "Diseño y Estudio de Necesidades",
  },
  {
    url: APDE2,
    thumbnail: APDE2,
    title: "Diseño y Estudio de Necesidades",
  },
  {
    url: APDE3,
    thumbnail: APDE3,
    title: "Diseño y Estudio de Necesidades",
  },
  {
    url: APDE4,
    thumbnail: APDE4,
    title: "Diseño y Estudio de Necesidades",
  },
  {
    url: APDE5,
    thumbnail: APDE5,
    title: "Planta de Esterilización y Sanitización",
  },
  {
    url: APDE6,
    thumbnail: APDE6,
    title: "Planta de Esterilización y Sanitización",
  },
  {
    url: APDE7,
    thumbnail: APDE7,
    title: "Planta de Esterilización y Sanitización",
  },
  {
    url: APDE8,
    thumbnail: APDE8,
    title: "Planta de Esterilización y Sanitización",
  },
  {
    url: APDE9,
    thumbnail: APDE9,
    title: "Planta de Esterilización y Sanitización",
  },
  {
    url: APDE10,
    thumbnail: APDE10,
    title: "Planta de Esterilización y Sanitización",
  },
  {
    url: APDE11,
    thumbnail: APDE11,
    title: "Planta de Esterilización y Sanitización",
  }, 
  {
    url: APDE12,
    thumbnail: APDE12,
    title: "Planta de Esterilización y Sanitización",
  },
];

const CasaHOficinas = [
  {
    url: Coco,
    thumbnail: Coco,
    title: "Construcción de Alberca",
  },
  {
    url: ChO1,
    thumbnail: ChO1,
    title: "Remodelación de Casa Habitación",
  },
  {
    url: ChO2,
    thumbnail: ChO2,
    title: "Remodelación de Casa Habitación",
  },
  {
    url: ChO3,
    thumbnail: ChO3,
    title: "Construcción de Casa Habitación",
  },
  {
    url: ChO4,
    thumbnail: ChO4,
    title: "Remodelación de Casa Habitación",
  },
  {
    url: ChO5,
    thumbnail: ChO5,
    title: "Remodelación de Casa Habitación",
  },
  {
    url: ChO6,
    thumbnail: ChO6,
    title: "Remodelación de Oficinas",
  },
  {
    url: ChO7,
    thumbnail: ChO7,
    title: "Construcción de Alberca",
  },
  {
    url: ChO8,
    thumbnail: ChO8,
    title: "Construcción de Alberca",
  },
  {
    url: ChO9,
    thumbnail: ChO9,
    title: "Remodelación Casa Habitación",
  },
  {
    url: ChO10,
    thumbnail: ChO10,
    title: "Mantenimiento de Oficinas",
  }
];

export default function ExperiencePage () {
  return (
    <div className='experience-page'>
      <h1 className='--bs-body-bg display-1 display-title text-center'>
        Experiencia
      </h1>
      <div className="container-fluid pb-5">
        {/* OBRAS PÚBLICAS */}
        <div className='row align-items-center col-md-8'>
          <h5 className="--bs-body-bg display-5 display-title pt-4 text-center">
            SERVICIOS OBRA PÚBLICA
          </h5>
        </div>
        <div className='row align-items-center'>
          <div className='col-md-8'>
            <ul class="list-group list-group-flush">            
              <li class="list-group-item mt-3 list-text bg-transparent">• AGUA POTABLE</li>
              <ul>
                <li class="list-group-item mt-3 list-text bg-transparent">• REHABILITACION DE RED/SISTEMA DE AGUA POTABLE EN NEZAHUALCOYOTL</li>
                <ul>
                  <li className='ms-4 mt-2 list-text-i'>
                    Ubicación: Nezahualcoyotl, edo. Mex
                  </li>
                  <li className='ms-4 mt-2 list-text-i'>
                    Concurso: "opn/ir-rrap-jzp-avjp-2020-fise-006"
                  </li >
                </ul>
                <li class="list-group-item mt-3 list-text bg-transparent">• CONSTRUCCIÓN DE SISTEMA DE AGUA POTABLE</li>
                  <ul>
                    <li className='ms-4 mt-2 list-text-i'>
                      Ubicación: Colonia Juarez Pantitlán
                    </li>
                    <li className='ms-4 mt-2 list-text-i'>
                      Concurso: "OPN/IR-CSAP-CJP-VJP-2020-FISE-002"
                    </li >
                  </ul>
                <li class="list-group-item mt-3 list-text bg-transparent">• ENTRONQUES DE AGUA POTABLE </li>
                  <ul>
                    <li className='ms-4 mt-2 list-text-i'>
                      Ubicación: Zona Centro, Guadalajara, Jaalisco
                    </li>
                  </ul>
              </ul>
              <li class="list-group-item mt-3 list-text bg-transparent">• CONSTRUCCION </li>
              <ul>
                <li class="list-group-item mt-3 list-text bg-transparent">• CONSTRUCCIÓN DE PLATAFORMAS </li>
                <ul>
                  <li className='ms-4 mt-2 list-text-i'>
                    Ubicación: Torre La Toscana en Zapopan, Jalisco
                  </li>
                  <li className='ms-4 mt-2 list-text-i'>
                    Destinadas para el área de contratistas y ventas de 5,550 m2
                  </li>
                </ul>
                <li class="list-group-item mt-3 list-text bg-transparent">• CONSTRUCCION DE PUENTE CENTENARIO-PERIFERICO NORTE</li>
              </ul>
              <li class="list-group-item mt-3 list-text bg-transparent">• PERFORACION DIRECCIONAL</li>
              <ul>
                <li class="list-group-item mt-3 list-text bg-transparent">• SUMINISTRO E INSTALACIÓN DE FIBRA ÓPTICA</li>
                <ul>
                  <li className='ms-4 mt-2 list-text-i'>
                    Ubicación: Tramo Tulancingo c-6 Pachuca, Hidalgo
                  </li>
                </ul>
              </ul>
              <li class="list-group-item mt-3 list-text bg-transparent">• DRENAJE</li>
              <ul>
                <li class="list-group-item mt-3 list-text bg-transparent">• REHABILITACIÓN DE RED DE ALCANTARILLADO</li>
                <ul>
                  <li className='ms-4 mt-2 list-text-i'>
                    Ubicación: Calle Oriente 36
                  </li>
                  <li className='ms-4 mt-2 list-text-i'>
                    Concurso: "OPN/IR-RRAL-OT36-CRF-2020-FISMDF-023"
                  </li >
                </ul>
              </ul>
              <li class="list-group-item mt-3 list-text bg-transparent">• POSTERIA Y TUBERIA</li>
              <li class="list-group-item mt-3 list-text bg-transparent">• PAVIMENTACIÓN</li>
              <li class="list-group-item mt-3 list-text bg-transparent">• SOLDADURA Y ESTRUCTURA</li>
            </ul>
          </div>
            <div className='col-md-4'>
              <ImageGalleryComponent images={obraPub} />
            </div>
        </div>

                {/* CASA HABITACION Y OFICINAS */}

        <div className='row align-items-center col-md-8'>
          <h5 className="--bs-body-bg display-5 display-title pt-4 text-center">
            CASA HABITACIÓN Y OFICINAS
          </h5>
        </div>
        <div className='row align-items-center'>
          <div className='col-md-8'>
            <ul class="list-group list-group-flush">
              <li class="list-group-item mt-3 list-text bg-transparent">• DISEÑO Y REMODELACION DE ALBERCA</li>
              <ul>
                <li className='ms-4 mt-2 list-text-i'>
                  Ubicación: COCOYOC, MORELOS
                </li>
                <li className='ms-4 mt-2 list-text-i'>
                  Obra Privada
                </li >
              </ul>
              <li class="list-group-item mt-3 list-text bg-transparent">• ADECUACION DE SPA</li>
              <ul>
                <li className='ms-4 mt-2 list-text-i'>
                  Ubicación: Casa #20, avante, CDMX
                </li>
                <li className='ms-4 mt-2 list-text-i'>
                  Obra privada
                </li >
              </ul>
              <li class="list-group-item mt-3 list-text bg-transparent">• MANTENIMIENTO A OFICINAS DE GP MORGAN</li>
              <ul>
                <li className='ms-4 mt-2 list-text-i'>
                  Ubicación: Delegación Miguel Hidalgo
                </li>
              </ul>
              <li class="list-group-item mt-3 list-text bg-transparent">• MANTENIMIENTO ANUAL DE TOWER CITY​</li>
              <ul>
                <li className='ms-4 mt-2 list-text-i'>
                  Ubicación: COYOACAN II
                </li>
              </ul>
              <li class="list-group-item mt-3 list-text bg-transparent">• CONSTRUCCIÓN Y REMODELACIÓN DE DIVERSAS SUCURSALES DEL BANCO HSBC </li>
              <ul>
                <li className='ms-4 mt-2 list-text-i'>
                  Ubicación: Nivel Nacional
                </li>
              </ul>
              <li class="list-group-item mt-3 list-text bg-transparent">• REMODELACION OFICINAS ESTADIO AZTECA</li>
              <ul>
                <li className='ms-4 mt-2 list-text-i'>
                Ubicación: Calz. De tlalpan 4261,CDMX
                </li>
                <li className='ms-4 mt-2 list-text-i'>
                  Obra privada
                </li >
              </ul>
            </ul>
          </div>
            <div className='col-md-4'>
              <ImageGalleryComponent images={CasaHOficinas} />
            </div>
        </div>

        {/* Servicios AnteProyectos */}

        <div className='row align-items-center col-md-8'>
          <h5 className="--bs-body-bg display-5 display-title pt-4 text-center">
            SERVICIOS: PROYECTOS Y ANTEPROYECTOS
          </h5>
        </div>
        <div className='row align-items-center'>
          <div className='col-md-8'>
            <ul class="list-group list-group-flush">
              <li class="list-group-item mt-3 list-text bg-transparent">• DISEÑO Y ESTUDIO DE NECESIDADES</li>
            </ul>
          </div>
          <div className='col-md-4'>
            <ImageGalleryComponent images={anteProyectos}/>
          </div>
        </div>
      </div>
    </div>
  )
}
