import React from 'react';
import PropTypes from 'prop-types';
import telephoneIcon from '../assets/img/telephone-fill.svg';

function PhoneLink({ phoneNumber }) {
  return (
    <div className="phone-link">
        <img src={telephoneIcon} alt='Telephone Icon' className="icon-large img-fluid w-10 mb-4 ps-5 pe-4 pt-3" />
      <a href={`tel:${phoneNumber}`}>
        <button type="button" class="btn btn-outline-success">Llamar {phoneNumber}</button>

      </a>
    </div>
  );
}

PhoneLink.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
};

export default PhoneLink;
