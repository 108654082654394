import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import LogoARASA from '../assets/img/LogoARASA.png';

const Sidebar = () => {

  const [navbarShow, setNavbarShow] = useState(false);

  const toggleNavbar = () => {
    setNavbarShow(!navbarShow);
  }

  return (
  <div className="nav flex-column">
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <NavLink to="/" activeClassName="active" className="navbar-brand">
          <img src={LogoARASA} alt="Logo" width="30" height="24" class="d-inline-block align-text-top img-fluid"/>
        </NavLink>
        <button onClick={toggleNavbar} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`${navbarShow ? 'show' : ''} collapse navbar-collapse`} id="navbarTogglerDemo02">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink to="/" className="nav-link">Inicio</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/about" className="nav-link">Nosotros</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/experience" className="nav-link">Experiencia</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/contact" className="nav-link">Contacto</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  );
};

export default Sidebar;