import React, { useRef} from 'react';
import { useIntersection } from "react-use";
import gsap from 'gsap';

import logo from '../assets/img/LogoARASA.png'
import targetIcon from '../assets/img/bullseye.svg'
import heart from '../assets/img/heart.svg'

export default function AboutPage () {  
  const fadeInStart = element => {
    gsap.to(element, 3, {
      opacity: 1,
      y: 30,
      ease: 'power4.out',
      stagger: {
        amount: 0.3
      }
    });
  };
  fadeInStart('.fadeInStart');
  
  const aboutRef = useRef(null);

  const intersection = useIntersection(aboutRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.3
  });

  const fadeIn = element => {
    gsap.to(element, 3, {
      opacity: 1,
      y: 30,
      ease: 'power4.out',
      stagger: {
        amount: 0.3
      }
    });
  };

  const fadeOut = element => {
    gsap.to(element, 3, {
      opacity: 0,
      y: 30,
      ease: 'power4.out',
    });
  };

  intersection && intersection.intersectionRatio < 0.3
  ? fadeOut('.fadeIn')
  : fadeIn('.fadeIn'); 
  

  return (
  <div className="about-page">
    <div className="container-fluid pb-5">
      {/* Quienes Somos */}
      <div className="row align-items-center fadeInStart">
        <div className="col-md-3 ">
          <div className='container'>
            <img src={logo} alt="ARASA Logo" className="img-fluid mb-4 w-100 ps-5 pe-5"/>
          </div>
        </div>      
        <div className="col-md-9 ">
          <h1 className="display-1 display-title">
            Quienes somos?
          </h1>
          <h3 className="paragraph pe-5 pt-4">
            Buscamos ser una empresa estratégica, innovadora y fiel para nuestros clientes, 
            que ayude a edificar y resolver todas sus necesidades referentes al diseño y 
            construcción de espacios.
          </h3>
          <h3 className="paragraph pe-5 pt-3">
            ARASA se distingue por ser una empresa que busca satisfacer y solucionar las 
            necesidades de espacio, urbanización y concepto de sus clientes.
          </h3>
        </div>
      </div>
      {/* Misión y Visión */}
      <div className="row align-items-center fadeInStart">
        <div className="col-md-3 ">
          <div className='container'>
            <img src={targetIcon} alt='Target Icon' className="img-fluid w-100 top mb-4 ps-5 pe-4 pt-300" />
          </div>
        </div>
        <div className="col-md-9 ">
          <h1 className="--bs-body-bg display-1 display-title pt-4">
            MISIÓN
          </h1>
          <h3 className="--bs-body-bg paragraph pe-5 pt-4">
          ARASA es una empresa que se constituye par unir ideas, pensamientos y logros de profesionistas
          independientes con experiencias de mas de 15 años en el ámbito de la construcción, diseño,
          remodelación, obra civil y administración de obras.
          </h3>
          <h1 className="--bs-body-bg display-1 display-title pt-4">
            VISIÓN
          </h1>
          <h3 className="--bs-body-bg paragraph pe-5 pt-4">
          Ser un proveedor referente en la construcción pública y privada, reconocido como líder en los 
          servicios de construcción y mantenimiento, con 
          base en el trabajo de calidad, el cumplimiento y las mejores practicas.
          </h3>
        </div>
      </div>
      {/* Valores */}
      <div ref={aboutRef} className='row align-items-center fadeIn'>
        <div className='col-md-3 '>
          <div className='container'>
          <img src={heart} alt='Heart Icon' className="img-fluid w-100 top mb-4 ps-5 pe-4 pt-300" />
          </div>
        </div>
        <div className='col-md-9 '>
          <h1 className="--bs-body-bg display-1 display-title pt-4">
            VALORES
          </h1>
          <h4 className="--bs-body-bg display-4 display-title pt-4">
            Honestidad
          </h4>
          <h3 className="--bs-body-bg paragraph pe-5 pt-4">
            Ser honesto es ser íntegro, implica congruencia entre lo que uno siente, piensa, dice y hace.
          </h3>
          <h4 className="--bs-body-bg display-4 display-title pt-4">
            Compromiso
          </h4>
          <h3 className="--bs-body-bg paragraph pe-5 pt-4">
            Realizamos el trabajo con profesionalidad y responsabilidad, ofreciendo lo mejor de nosotros 
            para el cumplimiento de los objetivos del cliente.
          </h3>
          <h4 className="--bs-body-bg display-4 display-title pt-4">
            Lealtad
          </h4>
          <h3 className="--bs-body-bg paragraph pe-5 pt-4">
            Implica ser fiel en todo momento a las organizaciones, a las creencias; 
            Consiste en nunca dar “la espalda” y siempre seguir adelante.
          </h3>
          <h4 className="--bs-body-bg display-4 display-title pt-4">
            Mejora Continua
          </h4>
          <h3 className="--bs-body-bg paragraph pe-5 pt-4">
            Adaptarse y ser competitivos, evitar la zona de confort y edificar el conocimiento. 
            Comprender lo que pasa a nuestro alrededor para anticiparnos, realizar innovación en 
            nuestras acciones y procesos.
          </h3>
        </div>
      </div>
      
    </div>
  </div>
  )
}