import React from 'react'
import PhoneLink from '../components/PhoneLink'
import EmailLink from '../components/EmailLink'

export default function ContactPage () {
  return (
    <div className='contact-page'>
        <div className='container-fluid min-vh-100'>
            <div className='row align-items-center'>
                <div className='col-md-3 '>
                    <h1 className='fancy-text ARASADispCont'>
                        ARASA
                    </h1>
                    <div className="fancy-text">
                        <h1 className="letter a text-start">A<span className="small-text ">RUMIR</span></h1>
                        <h1 className="letter r text-center">R<span className="small-text ">ODRIGUEZ</span></h1>
                        <h1 className="letter a text-end">A<span className="small-text">SOCIADOS</span></h1>
                    </div>
                </div>
    
                <div className='col-md-9'>
                    <h1 className='--bs-body-bg display-1 display-title'>
                        Contacto
                    </h1>
                    <h3 className='--bs-body-bg paragraph pe-2 pt-4'>
                        Puedes contactarnos a través de nuestro correo electrónico o llamarnos a nuestro número de teléfono.
                    </h3>
                    <h6 className="--bs-body-bg display-6 display-title pt-4">
                        JOSE ANDRES RODRIGUEZ ORTEGA
                    </h6>
                    <PhoneLink phoneNumber='+525569073402' />
                    <EmailLink emailAddress='arodriguez@cons-arasa.com' subject="Consulta"/>
                    <h6 className="--bs-body-bg display-6 display-title pt-4">
                        JUAN JOSE ARUMIR OLVERA
                    </h6>
                    <PhoneLink phoneNumber='+525585633129​' />
                    <EmailLink emailAddress='jarumir@cons-arasa.com' subject="Consulta"/>
                </div>
            </div>
        </div>
    </div>
  )
}
