import React, {useLayoutEffect, useRef} from 'react'
import logo from '../assets/img/LogoARASA.png'
import logoSimp from '../assets/img/LogoSimple.png'
import gsap from 'gsap'

export default function HomePage () {
  const reference = useRef(null);
  useLayoutEffect(() => {
    let contxt = gsap.context(() => {
      const time1 = gsap.timeline()    
      time1.from("#TopLogo",{
        opacity: 0,
        y: "+=30",
        stagger: 0.3,
      })
      .to("#TopLogo",{
        opacity: 1,
        y: "+=30",
        stagger: 0.3,
      })
      .from("#ARASADisp",{
        opacity: 0,
        y: "+=30",
        stagger: 0.3,
      })
      .to("#ARASADisp",{
        opacity: 1,
        y: "+=30",
        stagger: 0.3,
      })
      .from("#ARASALetters",{
        opacity: 0,
        y: "+=30",
        stagger: 0.3,
      })
      .to("#ARASALetters",{
        opacity: 1,
        y: "+=30",
        stagger: 0.3,
      })
      time1.from('.word', {
        y: 100,
        stagger: {
          each: 0.03,
        },
      });
    },reference)
    return () => contxt.revert()
  },[])

  return (
    <div className='home-page' ref={reference}>
      <div className='container-fluid min-vh-100'>
        <div className="row align-items-center " id='TopLogo'>
          <div className="col-12 col-md-5 col-lg-4 col-xl-3">
            <div className='container text-center text-md-start'>
              <img src={logo} alt="ARASA Logo" className="img-fluid mb-4 w-50 ps-2 pe-5" />
            </div>
          </div>  
        </div>
        <div className="row align-items-center col-md-12" id='ARASADisp'>
            <h1 className="text-center display-home">
              ARASA
            </h1>
        </div>
        <div className="row align-items-center" id='ARASALetters'>
          <div className="col-md-6 ">
            <div className="fancy-text" >
                <h1 className="letter-home text-start ps-0 ps-md-2">A<span className="small-text-home ">RUMIR</span></h1>
                <h1 className="letter-home text-center pe-1 pe-md-5">R<span className="small-text-home ">ODRIGUEZ</span></h1>
                <h1 className="letter-home text-end">A<span className="small-text-home">SOCIADOS</span></h1>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='text-end text-sm-center'>
              <img src={logoSimp} alt="ARASA Logo" className="img-fluid mb-sm-5 mb-4 w-50 ps-2 pe-5 d-sm-inline d-none" />
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-12 text-start lema mt-sm-5 mt-md-2">{
              'LA CLAVE DEL ÉXITO ES LA CALMA'.split('').map((word) => {
                return word === ' ' ? <div className='word'>&nbsp;</div> : <div className="word "> {word} </div> 
              })
          }
          </div>
        </div>

      </div>

    </div>
  )
}
