import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const ImageGalleryComponent = ({ images }) => {
  // Formatear las imágenes para el componente ImageGallery
  const formattedImages = images.map((image) => ({
    original: image.url,       
    thumbnail: image.thumbnail,
    originalHeight: 150, 
    originalWidth: 150,
    description: image.title,
  }));

    const renderImageWithTitle = (item) => {
      return (
        <div className="image-gallery-item">
          <img src={item.original} alt={item.description} className="img-fluid" />
          {item.description && (
            <div className="image-gallery-title">
              <h4>{item.description}</h4>
            </div>
          )}
        </div>
      );
    };

  return (
    <ImageGallery 
      items={formattedImages}
      renderItem={renderImageWithTitle} // Usa renderItem para personalizar
      showPlayButton={false} 
      showFullscreenButton={false}
    />
  );
};

export default ImageGalleryComponent;
