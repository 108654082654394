import React from 'react';
import PropTypes from 'prop-types';
import emailIcon from '../assets/img/envelope-at-fill.svg';

function EmailLink({ emailAddress, subject }) {
  return (
    <div className="email-link">
        <img src={emailIcon} alt='Email Icon' className="icon-large img-fluid w-10 mb-4 ps-5 pe-4 pt-3" />
        <a href={`mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`}>
            <button type="button" class="btn btn-outline-success">{emailAddress}</button>
        </a>
    </div>
  );
}

EmailLink.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  subject: PropTypes.string,
};

EmailLink.defaultProps = {
  subject: 'Consulta',
};

export default EmailLink;
